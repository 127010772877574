<template>
  <div id="SurveySubmit">
    <div class="main">
      <Spin fix v-if="loading"></Spin>
      <!--startprint-->
      <div class="text-center" style="margin-bottom:10px;font-size:20px;">
        <b>{{Bill.Title}}</b>
      </div>
      <div class="Foreword" v-html="Bill.Foreword"></div>
      <div class="ques-lst">
        <div :key="i" class="ques-item" v-for="(a, i) in Questions">
          <div>
            <b>{{a.QuesNumber}}、{{a.Title}}</b>
            <span v-if="a.TypeId==1||a.TypeId==2">（{{a._answer}}）</span>
          </div>
          <ul class="choice-ul" v-if="a.TypeId==1||a.TypeId==2">
            <li :key="j" v-for="(b,j) in a.ChoiceItems">{{b.Letter}}、{{b.Content}}</li>
          </ul>
          <div style="padding-left:1em;" v-if="a.TypeId==3">{{a._answer||'未作答'}}</div>
          <div style="padding-left:1em;" v-if="a.TypeId==4">
            <Icon :type="a._answer>=2?'md-star':'md-star-outline'" />
            <Icon :type="a._answer>=4?'md-star':'md-star-outline'" />
            <Icon :type="a._answer>=6?'md-star':'md-star-outline'" />
            <Icon :type="a._answer>=8?'md-star':'md-star-outline'" />
            <Icon :type="a._answer>=10?'md-star':'md-star-outline'" />
          </div>
        </div>
      </div>
      <div class="Postscript" v-html="Bill.Postscript"></div>
      <!--endprint-->
      <div class="flex justify-between" style="margin-top:10px">
        <div class>总得分：{{OtherInfo.Score}}</div>
        <div class="flex">
          <img
            :src="OtherInfo.SignImg"
            :title="OtherInfo.SignUserName"
            alt
            style="max-width:100px;max-height:80px;"
          />
          <span>{{OtherInfo.SignTime}}</span>
        </div>
      </div>
      <div class="flex justify-center noprint" style="margin-top:10px" v-show="!loading">
        <!-- <Button @click="toPrint" type="primary">
          <Icon type="md-print" />打印
        </Button>-->
        <Button @click="exportFile" type="primary">
          <Icon type="md-download" />导出
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      OrderId: 0,
      UserId: 0,
      Bill: {},
      Questions: [],
      OtherInfo: {},
      flag: true,
    };
  },
  mounted: function () {
    this.OrderId = this.$route.query.orderid;
    this.UserId = this.$route.query.userid;
    this.req();
  },
  methods: {
    req() {
      this.loading = true;
      this.$axios
        .get("/Api/CmsSurvey/GetOrderSurvey", {
          params: { OrderId: this.OrderId, UserId: this.UserId },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.Bill = res.data.Bill;
            this.OtherInfo = res.data.OtherInfo;
            let ques = res.data.Questions;
            if (res.data.Answers.length > 0) {
              for (let a of res.data.Answers) {
                for (let b of res.data.Questions) {
                  if (a.SurveyQuesId == b.Id) {
                    if (b.TypeId == 1) {
                      b._answer = this.$util.index2Abc(a.UserAnswer, "ABC");
                    } else if (b.TypeId == 2) {
                      b._answer = a.UserAnswer.split(",")
                        .map((v) => this.$util.index2Abc(v, "ABC"))
                        .join(",");
                    } else {
                      b._answer = a.UserAnswer;
                    }
                  }
                }
              }
            }
            this.Questions = ques;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toPrint() {
      window.print();
    },
    exportFile() {
      window.open(
        `/export/surveytodocx?orderId=${this.OrderId}&userid=${this.UserId}`
      );
    },
  },
};
</script>
<style lang="less">
#SurveySubmit {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: scroll;
  .main {
    width: 700px;
    position: relative;
    padding: 20px 0;
  }
  .Foreword {
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e8eaec;
  }
  .Postscript {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #e8eaec;
  }
  .ques-item {
    margin-bottom: 10px;
  }
  .ques-item li {
    list-style: none;
  }
  .choice-ul {
    color: #515a6e;
    padding-left: 1em;
  }
}
@media print {
  .noprint {
    display: none;
  }
}
</style>